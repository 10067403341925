<template>
  <div>
    <AppTable
      store-module="sliders"
      :table-fields="tableFields"
      :filters="filters"
      :actions="actions"
      :form-fields="formFields"
      :table-header="tableHeader"
    />
  </div>
</template>

<script>
import AppTable from "@/components/table/AppTable.vue";
import i18n from "@/libs/i18n/index";

export default {
  name: "Sliders",
  components: {
    AppTable,
  },
  data() {
    return {
      tableFields: [
        { key: "edit_delete", label: i18n.t("edit_delete") || "Edit/Delete" },
        { key: "title", label: i18n.t("title") || "title" },
        { key: "image", label: i18n.t("image") || "image" },
        { key: "title_ar", label: i18n.t("title_ar") || "title_ar" },
        { key: "order", label: i18n.t("order") || "order" },
      ],
      filters: [{ name: "createdAt", componentType: "AppDatePicker" }],
      actions: [
        { label: "Edit", variant: "primary", event: "edit" },
        { label: "Delete", variant: "danger", event: "delete" },
      ],
      formFields: [
        {
          component: "AppInput",
          attrs: {
            vModel: "title_ar",
            type: "text",
            required: true,
            label: i18n.t("title_ar") || "Title in Arabic",
          },
        },
        {
          component: "AppInput",
          attrs: {
            vModel: "title",
            type: "text",
            required: true,
            label: i18n.t("title_en") || "Title in English",
          },
        },
        {
          component: "AppInput",
          attrs: {
            vModel: "order",
            type: "number",
            required: true,
            label: i18n.t("order") || "order",
          },
        },
        {
          component: "AppTextarea",
          attrs: {
            vModel: "description_ar",
            type: "text",
            required: true,
            label: i18n.t("description_ar") || "description in Arabic",
          },
        },
        {
          component: "AppTextarea",
          attrs: {
            vModel: "description",
            type: "text",
            label: i18n.t("description_en") || "description in English",
          },
        },
        {
          component: "AppInput",
          attrs: {
            vModel: "apply_starts_at_text",
            type: "text",
            label: i18n.t("apply_starts_at_text") || "apply_starts_at_text",
          },
        },
        {
          component: "AppDatePicker",
          attrs: {
            vModel: "apply_starts_at",
            mode: "single",
            label: i18n.t("apply_starts_at") || "date",
          },
        },
        {
          component: "AppInput",
          attrs: {
            vModel: "apply_ends_at_text",
            type: "text",
            label: i18n.t("apply_ends_at_text") || "apply_ends_at_text",
          },
        },
        {
          component: "AppDatePicker",
          attrs: {
            vModel: "apply_ends_at",
            mode: "single",
            label: i18n.t("apply_ends_at") || "date",
          },
        },
        {
          component: "AppInput",
          attrs: {
            vModel: "judgment_ends_at_text",
            type: "text",
            label: i18n.t("judgment_ends_text") || "judgment_ends_text",
          },
        },
        {
          component: "AppDatePicker",
          attrs: {
            vModel: "judgment_ends_at",
            mode: "single",
            label: i18n.t("judgment_ends_at") || "date",
          },
        },
        {
          component: "AppInput",
          attrs: {
            vModel: "link",
            type: "url",
            label: i18n.t("link") || "Link",
          },
        },
        {
          component: "AppInput",
          attrs: {
            vModel: "link_text",
            type: "text",
            label: i18n.t("link_text_en") || "Link text in English",
          },
        },
        {
          component: "AppInput",
          attrs: {
            vModel: "link_text_ar",
            type: "text",
            label: i18n.t("link_text_ar") || "Link text in Arabic",
          },
        },
        {
          component: "AppGallery",
          attrs: {
            vModel: "image",
            value: "formData['image']",
            multiple: false,
            required: true,
            label: i18n.t("image") || "image",
          },
        },
      ],
      tableHeader: {
        addNewBtn: true,
        multiKeySearch: true,
      },
    };
  },
};
</script>
